<template>
	<section class="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
		<div class="container flex flex-col items-center justify-center px-5 mx-auto my-8">
			<div class="max-w-md text-center">
				<h2 class="mb-8 font-extrabold text-9xl dark:text-gray-600">
					<span class="sr-only">
						<font style="vertical-align: inherit;">
							<font style="vertical-align: inherit;">Error</font>
						</font>
					</span>
					<font style="vertical-align: inherit;">
						<font style="vertical-align: inherit;">404</font>
					</font>
				</h2>
				<p class="text-2xl font-semibold md:text-3xl">
					<font style="vertical-align: inherit;">
						<font style="vertical-align: inherit;">Lo sentimos, no pudimos encontrar esta página.</font>
					</font>
				</p>
				<p class="mt-4 mb-8 dark:text-gray-400">
					<font style="vertical-align: inherit;">
						<font style="vertical-align: inherit;">Pero no te preocupes, puedes encontrar muchas otras cosas en nuestra página de inicio.</font>
					</font>
				</p>
				<a rel="noopener noreferrer" href="/" class="px-8 py-3 font-semibold text-white border-0 bg-blue-900 rounded">
					<font style="vertical-align: inherit;">
						<font style="vertical-align: inherit;"><i class="fa-solid fa-arrow-left"></i> Volver a la página de inicio</font>
					</font>
				</a>
			</div>
		</div>
	</section>
</template>