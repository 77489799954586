export default {
  "header": {
    "primary": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País/región"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "/assets/img/logo_mx.png"])}
    },
    "secondary": {
      "menu": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturación"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reseñas"])},
        "quote_wholesale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotizar Mayoreo"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
        "products": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productos"])},
          "submenu": {
            "first_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los Productos"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbuja"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón Normales"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón Autoarmables"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Packing List"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material de Embalaje"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresoras y Rollos Térmicos"])}
              }
            },
            "second_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos Premium al por Mayor"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos Premium"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos Premium con Portaguías"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos 100% Recicladas"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres de Papel para Envíos"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos Biodegradables"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos Premium con Diseños"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas para Envíos Premium con Portaguías al por Mayor"])}
              }
            },
            "third_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas de Plástico"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas con Advertencia de Asfixia"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas de Celofán"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsas de Plástico con Cierre Tipo Ziploc"])}
              }
            },
            "fourth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón Regulares (RSC)"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las Medidas"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón Cúbicas"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Envíos de 1 kg"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Envíos de 2 kg"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Envíos de 3 kg"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Envíos de 4 y 5 kg"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Envíos de 6 a 10 kg"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón al por Mayor"])}
              }
            },
            "fifth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón Armables"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Envíos"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón Cuadradas"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Tazas"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Empaque"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de Cartón para Ropa"])}
              }
            },
            "sixth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbujas"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbuja Amarillos"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbuja Jiffylite Kraft"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbuja Blancos"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbuja Rosas"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbuja Aqua"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres con Burbuja Metálicos"])}
              }
            },
            "seventh_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Packing List"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Packing List"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Packing List Transparente al por Mayor"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Packing List Enclosed con Panel Naranja al por Mayor"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Packing List / Invoice Enclosed al por Mayor"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Packing List / Full Packing List Enclosed al por Mayor"])}
              }
            },
            "eighth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material de Embalaje"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cintas Adhesivas"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollos de Plástico para Emplayar"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material de Protección"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafia en Rollo Blanca"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollo de Papel Kraft"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleje Negro 1/2”"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grapa Para Fleje 1/2”"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquineros de Cartón Kraft"])}
              }
            },
            "ninth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresoras y Rollos Térmicos"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impresoras Térmicas"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollos Térmicos"])}
              }
            },
            "tenth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres de Papel para Envíos"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Rígidos para Envíos"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Kraft con Fuelle para Envíos"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres de Papel Burbuja “Honeycomb”"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Kraft con Fuelle para Envíos al por Mayor (1 Tarima)"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobres Rígidos Kraft al por Mayor (1 Tarima)"])}
              }
            },
            "eleventh_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Cajas de Cartón Regulares"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Bolsas para Envíos Premium"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Bolsas para Envíos Premium Con Portaguías"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres con Burbuja Amarillos para Envíos"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres con Burbuja Blancos para Envíos"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres con Burbuja Plateados Metálicos para Envíos"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres de Polietileno con Burbuja Rosas Para Envíos"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres de Polietileno con Burbuja Aqua Para Envíos"])},
                "ninth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres con Burbuja Jiffylite Kraft"])},
                "tenth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres Packing List"])},
                "eleventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Bolsas Biodegradables Para Envíos"])},
                "twelfth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestras de Sobres Kraft con Fuelle"])}
              }
            }
          }
        },
        "by_industry": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por Industria"])},
          "submenu": {
            "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empaques para Enviar Cosméticos"])},
            "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empaques para Enviar Libros"])},
            "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empaques para Enviar Ropa"])},
            "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empaques para Enviar Refacciones y Autopartes"])},
            "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empaques Biodegradables para Alimentos"])}
          }
        },
        "more_information": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información"])},
          "submenu": {
            "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre Nosotros"])},
            "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas Frecuentes"])},
            "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastrear Mi Paquete"])},
            "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
            "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Devoluciones y Reembolsos"])},
            "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])}
          }
        }
      }
    }
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el tipo de caja de cartón"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escoge alguna de las siguientes opciones:"])},
    "products": {
      "boxes_regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas Regulares"])},
      "boxes_self_assembling_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas Autoarmables"])}
    }
  },
  "calculator": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricación de Cajas de Cartón Regulares a la Medida"])},
    "num_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["134 Reseñas"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrica una caja para empaque y envío con las medidas y especificaciones exactas para tu producto."])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliza nuestro cotizador. Introduce las medidas internas que deseas para tu caja, largo, ancho y alto, para calcular el costo total, unitario y el mínimo de fabricación para cualquier medida de cajas de cartón corrugado."])},
    "link_informative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href='https://empresa.parapaquetes.com/2023/08/28/que-son-las-flautas-y-la-resistencia-ect-en-el-carton-corrugado' target='_blank' class='text-blue-600 text-sm font-semibold hover:underline'>Todo sobre Flautas y ECT en el cartón corrugado <i class='fa-solid fa-link text-sm'></i></a>"])},
    "breadcrumbs": {
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
      "path": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas regulares"])}
      }
    },
    "label": {
      "type_flute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el tipo de flauta:"])},
      "type_resistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resistencia de la caja:"])},
      "type_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione la unidad:"])}
    },
    "input": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largo"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancho"])},
      "tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])}
    },
    "button": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])}
    },
    "value": {
      "flute_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flauta C Kraft"])},
      "flute_bc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flauta BC Kraft"])},
      "flute_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flauta E Kraft"])}
    },
    "result": {
      "manufacturing_minimun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MINIMO DE FABRICACIÓN"])},
      "price_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRECIO POR CAJA"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
      "text_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione el tipo de caja, el largo, el ancho y el alto para realizar la cotización"])}
    },
    "manufacturing_policies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politicas de fabricación:"])},
      "list": {
        "no_returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez efectuado el pago, no se aceptan cancelaciones ni devoluciones"])},
        "error_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fabricación de las cajas tiene un +- 10% de margen de error en la cantidad de piezas"])},
        "envio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El costo de envío será proporcionado por nuestro equipo de ventas y se agregará a la cotización"])}
      }
    },
    "error": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerta!"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importante:"])},
      "required": {
        "type_flute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de flauta es obligatorio"])},
        "color_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El color de la caja es obligatorio"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El largo no puede estar vacío"])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ancho no puede estar vacío"])},
        "tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El alto no puede estar vacío"])},
        "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes aceptar los términos y condiciones"])}
      },
      "number": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es un número no válido"])}
      },
      "minimum_constraint": {
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fabricacion de tu caja requiere de un suaje (?) debido a que el tamaño elegido es menor al estandar. El costo del suaje es adicional al de la cotización de las cajas."])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La fabricacion de tu caja requiere de un suaje (?) debido a que el tamaño elegido es menor al estandar. El costo del suaje es adicional al de la cotización de las cajas."])}
      },
      "max_constraint": {
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El largo del pliego debe de ser menor a 290 cm, Para calcular el largo del pliego se utiliza la siguiente formula: ((Largo*2)+(Ancho*2)+6)"])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ancho del pliego debe de ser menor a 140 cm, Para calcular el ancho del pliego se utiliza la siguiente formula: (Ancho + Alto + 3)"])}
      }
    },
    "checkbox": {
      "terms_conditions": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy de acuerdo con los <a href='https://parapaquetes.com/pages/terminos-y-condiciones' target='_blank' class='text-blue-600 hover:underline dark:text-blue-500'>términos y condiciones</a> y politicas de fabricación"])}
      }
    }
  },
  "confirmation": {
    "title": {
      "send_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar cotización"])},
      "quote_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen del pedido"])}
    },
    "text": {
      "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensiones"])},
      "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio unitario"])},
      "amount_boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de cajas"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio total"])},
      "cardboard_boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas de cartón"])},
      "for_manufacturing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para Fabricación"])}
    },
    "span": {
      "without_iva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin iva"])},
      "with_iva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con iva"])}
    },
    "button": {
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa (Opcional)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle y número exterior"])},
      "suburb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonia"])},
      "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])}
    },
    "error": {
      "required": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo nombre es requerido"])},
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo apellido es requerido"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo email es requerido"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo del teléfono es requerido"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de calle es requerido"])},
        "suburb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de colonia es requerido"])},
        "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo de código postal es requerido"])}
      },
      "validation": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo tiene que ser un email válido"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo tiene que ser un teléfono válido"])}
      }
    },
    "alert": {
      "successful": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tu solicitud ha sido enviada exitosamente!"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En las siguientes 24 horas hábiles un ejecutivo de ventas se comunicará para darle seguimiento a tu solicitud."])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href='https://empresa.parapaquetes.com/contact/' target='_blank' style='font-weight: 700; text-decoration: underline; text-decoration-thickness: 1px; text-align: center;'> Para cualquier duda o comentario, haz click aqui para contactarnos</a>"])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al enviar la cotización"])}
      }
    }
  },
  "page_self_assembling_box": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricación de Cajas de Cartón Autoarmables a la Medida"])},
    "num_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 Reseñas"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestro cotizador en línea de Cajas de Cartón Autoarmables a la medida aún está en construcción, comparte con nosotros tus datos utilizando el siguiente formulario y te contactaremos para apoyarte a cotizar las cajas que deseas."])},
    "breadcrumbs": {
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
      "path": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajas autoarmables"])}
      }
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
      "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa (Opcional)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe lo que necesitas (Opcional)"])}
    },
    "contact_means": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para más información puedes contactarnos por"])},
      "social_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])}
    },
    "error": {
      "required": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo nombre es requerido"])},
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo apellido es requerido"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo email es requerido"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo del teléfono es requerido"])}
      },
      "validation": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo tiene que ser un email válido"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El campo tiene que ser un teléfono válido"])}
      }
    },
    "button": {
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
    },
    "alert": {
      "successful": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tu solicitud ha sido enviada exitosamente!"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En las siguientes 24 horas hábiles un ejecutivo de ventas se comunicará para darle seguimiento a tu solicitud."])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href='https://empresa.parapaquetes.com/contact/' target='_blank' style='font-weight: 700; text-decoration: underline; text-decoration-thickness: 1px; text-align: center;'> Para cualquier duda o comentario, haz click aqui para contactarnos</a>"])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al enviar la cotización"])}
      }
    }
  },
  "terms_conditions": {
    "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leyenda:<span class='font-semibold italic'> “Al realizar mi pedido, confirmo que he leído y estoy de acuerdo con los <span class='underline'>Términos y Condiciones</span> de compra”.</span>"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones de Compra"])},
    "main_content": {
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>¡Bienvenido a Para Paquetes!</span> Suministros Para Paquetes, S.A. de C.V. y/o sus filiales (“Para Paquetes”) tiene(n) su domicilio ubicado en Belisario Domínguez, No. 2470, piso 3, Colonia Obispado, en Monterrey, Nuevo León, C.P. 64060, y te ofrecemos funciones de sitio web y otros productos, así como servicios de embalaje. Al ingresar y utilizar este portal de Internet, cuyo nombre de dominio es <a href='https://parapaquetes.com/' class='text-blue-600'>www.parapaquetes.com</a> (“Sitio Web”), propiedad de Para Paquetes, tú como usuario estás aceptando los Términos y Condiciones de Compra (“Términos”) contenidos en el presente convenio y declaras expresamente su aceptación, utilizando para tal efecto medios electrónicos, en términos de lo dispuesto por el artículo 1803 del Código Civil Federal."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El uso de la presente página de Internet le otorga al público en general la condición de usuario (en adelante “Usuario”/“Usuarios”) e implica la aceptación, plena e incondicional, de todas y cada una de las condiciones generales y particulares incluidas en estos Términos en el momento mismo en que el Usuario acceda al Sitio Web. En caso de no aceptar en forma absoluta y completa estos Términos, deberás abstenerte de acceder, utilizar, observar y comprar en nuestra página web. En caso de que accedas, utilices, observes y/o compres a través de nuestro Sitio Web, se considerará como una aceptación absoluta y expresa de tu parte a los presentes Términos."])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cualquier modificación a los presentes Términos será realizada cuando Para Paquetes, lo considere apropiado, siendo exclusiva responsabilidad del Usuario asegurarse de tomar conocimiento de tales modificaciones."])},
      "fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor revisa cuidadosamente los siguientes lineamientos. Si tienes alguna duda respecto a los términos y condiciones, por favor escribe un correo electrónico a <a href='mailto:contacto", "@", "parapaquetes.com' class='text-blue-600'>contacto", "@", "parapaquetes.com</a> o envíanos un mensaje de WhatsApp al número <a href='tel:8126070095' class='text-blue-600'>+52 8126070095</a>."])},
      "fifth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrecemos una amplia gama de productos y en ocasiones pueden aplicarse condiciones adicionales. Al ingresar y comprar nuestros materiales de empaque, te sujetas a las siguientes condiciones de compra:      "])}
    },
    "service_paragraph": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Paquetes te ofrece el servicio de fabricación de cajas de cartón a las medidas y características exactas que tú nos indiques al momento de llenar tu formulario y realizar tu compra en nuestro Sitio Web."])}
    },
    "prices_taxes": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios, impuestos, cargos por manejo y especificaciones"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás de acuerdo en que todo lo relacionado a precios, materiales y servicios en el Sitio Web de Para Paquetes están sujetos a cambio sin previo aviso. Las descripciones de los materiales son lo más precisas posible; sin embargo, podrían variar."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Paquetes se reserva el derecho de hacer ajustes de precios y productos por razones que incluyen, pero no están limitadas a, cambios en las condiciones del mercado, productos descontinuados, falta de disponibilidad, cambios en los precios de los fabricantes y errores de publicidad. "])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los precios publicados son importes netos en pesos mexicanos y no incluyen gastos de envío, cargos por manejo, impuestos y/o aranceles o requisitos especiales de embalaje para exportaciones. Los impuestos o aranceles serán pagados por el Usuario, siendo su total responsabilidad."])}
    },
    "service_payment_billing": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago del Servicio y facturación"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deberás realizar el pago total del valor de los servicios que desees adquirir en una sola exhibición y al momento de procesar tu orden de compra, debiendo quedar liquidado en su totalidad para que podamos enviar tu pedido. Una vez recibido, procesado y debidamente reflejado tu pago, te llegará una confirmación de compra a tu correo de registro. Para Paquetes acepta tarjetas de crédito, depósitos, transferencias bancarias, pagos por Apple Pay, Dinners Club, Discover, Meta Pay, Google Pay, Mastercard, PayPal, Shop Pay, Venmo, Visa, American Express."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que requieras factura, podrás tramitarla en la sección de “Facturación” en nuestro Sitio Web, ingresando tu número de orden y el monto total sin centavos. Si tus datos tienen algún error y generas una factura incorrecta, deberás comunicarte con Para Paquetes a nuestro correo de <a href='mailto:contacto", "@", "parapaquetes.com' class='text-blue-600'>contacto", "@", "parapaquetes.com</a> o chat para que esta sea cancelada y te podamos generar la nueva factura."])}
    },
    "shipping_delivery_products": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envío y entrega de productos"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los envíos únicamente podrán ser realizados dentro del territorio de México."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tiempo promedio de fabricación de tus cajas a la medida exacta solicitada es de aproximadamente 15 (quince) días hábiles, una vez que hayas recibido tu correo de confirmación de compra. Cuando tu pedido esté listo, Para Paquetes te informará a través de un correo y te compartirá una lista de paqueterías disponibles de acuerdo a tu domicilio para enviarte tu pedido. Una vez que te compartimos esa lista, tendrás un lapso máximo de 24 horas para elegir la paquetería de tu preferencia y darnos respuesta. De lo contrario, si por algún motivo no recibimos tu respuesta en el lapso de tiempo comentado, al aceptar estos Términos autorizas a Para Paquetes a que seleccione una paquetería aleatoria para enviarte tu pedido, sin responsabilidad para este último."])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manejamos opciones para adquirir envíos urgentes o con alguna paquetería en específico (adicional a las que aparezcan en la lista que te enviemos); estas opciones las podrás adquirir con un costo adicional. Para ello, contacta al personal de Para Paquetes a través de Servicio al Cliente."])},
      "fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando sea seleccionada la paquetería, recibirás una notificación de Para Paquetes informándote que tu pedido ha sido enviado, así como tu número de guía de rastreo. La entrega de tu pedido dependerá de los tiempos establecidos por la paquetería de tu selección, te invitamos a revisar los términos y condiciones establecidos en las plataformas de logística para tener una fecha estimada de entrega."])},
      "fifth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que solicites alguna paquetería en específico, te invitamos a revisar los términos y condiciones establecidos en su plataforma de logística para tener una fecha estimada de entrega. Para Paquetes no controla ni manipula la distribución de tu pedido, ya que la contratación logística es con un tercero. Por lo tanto, una vez que tu pedido es enviado, Para Paquetes no tendrá responsabilidad alguna por atraso en la entrega (una vez enviado tu paquete), por daño o manipulación de tu pedido. No obstante lo anterior, te podemos apoyar en temas de soporte y seguimiento de tu caso con la paquetería correspondiente."])},
      "sixth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que exista algún problema o incidencia con tu paquete, haya tenido revisión de alguna autoridad competente, situación de fuerza mayor, o robo contacta de inmediato a servicio al cliente para que te brinden el soporte necesario."])}
    },
    "responsibility": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsabilidad"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Paquetes tiene la responsabilidad de enviarte la cantidad de material de empaque que hayas solicitado en tu orden de compra de acuerdo con las características que tú pediste. En caso de que tu pedido llegue incompleto puedes comunicarte con Servicio al Cliente."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptas que la elección de material de empaque y compra del mismo es tu total responsabilidad, por lo que bajo este acto deslindas totalmente a Para Paquetes."])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconoces que Para Paquetes no se hace responsable del material que vayas a transportar dentro de los productos que adquieras en nuestro Sitio Web. Es responsabilidad del Usuario revisar el tipo de material que transportará y elegir el empaque que mejor le convenga para que su material se conserve. Si el producto que transportas dentro del material de empaque adquirido en nuestro Sitio Web sufre daños durante la entrega será responsabilidad del Usuario únicamente, por lo que este último deslinda bajo este acto a Para Paquetes de cualquier tipo de responsabilidad que pudiera tener por daños al material transportado por el Usuario en los productos de Para Paquetes."])},
      "fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que no sepas qué tipo de empaque es el más adecuado para tu material y/o necesidades, puedes contactar a Para Paquetes al correo electrónico: <a href='mailto:contacto", "@", "parapaquetes.com' class='text-blue-600'>contacto", "@", "parapaquetes.com</a>, o envíanos un mensaje de WhatsApp al <a href='tel:8126070095' class='text-blue-600'>+52 8126070095</a> para brindarte una asesoría personalizada, la cual será sin responsabilidad alguna a su cargo."])}
    },
    "service_guarantee": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantía del Servicio"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Paquetes y sus afiliados rechazan expresamente toda garantía expresa o implícita relacionada con los productos, incluyendo, sin limitar a, cualquier garantía de comercialización o algún propósito en particular."])}
    },
    "copyright": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propiedad de los Materiales, Marcas Registradas y Derechos de Autor"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contenido de este Sitio Web no se debe copiar, difundir, descargar, transmitir, duplicar o reproducir sin el previo permiso por escrito de Para Paquetes o el titular de la propiedad intelectual correspondiente. Este Sitio Web y todo su contenido, incluyendo todas las imágenes, diseños, fotografías, texto y otros materiales (el “Contenido”) son derechos de autor, propiedad intelectual o marcas registradas que pertenecen y/o son licencia de Para Paquetes o sus afiliados, o son propiedad de sus respectivos dueños."])}
    },
    "privacy": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta la Política de Privacidad de Para Paquetes para información más detallada sobre la recopilación de datos y las condiciones de dicho proceso por nosotros, así como la confidencialidad y medidas de seguridad que implementamos.  Al visitar nuestro Sitio Web, procesar o realizar cualquier compra, otorgas tu consentimiento para el procesamiento de tu información personal de acuerdo con nuestra política de Privacidad."])}
    },
    "applicable": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicable"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al acceder a este Sitio Web aceptas que las leyes de la Ciudad de Monterrey, Estados Unidos Mexicanos (sin tener en cuenta los conflictos de principios legales) se aplicarán a todos los asuntos relacionados con el uso de este Sitio Web y la compra de productos disponibles a través del mismo. Cualquier conflicto, disputa o litigio que surge de estos Términos, o cualquier compra que realices a través de este Sitio Web, será llevado exclusivamente en la Ciudad de Monterrey y tú como Usuario y cliente das tu consentimiento para que la jurisdicción de las autoridades competentes y Tribunales sea la ciudad mencionada, renunciando al derecho de cambiar de sede."])}
    },
    "entire_agreement": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acuerdo Completo"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estos Términos constituyen el convenio completo y el acuerdo entre Para Paquetes y tú (Usuario) y reemplaza cualquier acuerdo o convenio celebrado previamente, incluyendo cualquier declaración oral hecha por los representantes de Para Paquetes. En la medida en que lo relacionado con el Sitio Web esté en conflicto o sea incompatible con estos Términos, estos Términos tendrán prioridad."])}
    },
    "text_terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UNA VEZ LEÍDO LOS PRESENTES TÉRMINOS Y CONDICIONES, ACEPTO LA VERACIDAD Y LEGITIMACIÓN DE LOS MISMOS Y ACEPTO LOS LINEAMIENTOS POR LOS CUALES SERÁ REGIDA LA RELACIÓN COMERCIAL."])}
  }
}