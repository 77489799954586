<template>
  <div class="bg-azul-default">
    <div class="px-4 2xl:px-16">
      <nav class="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div class="flex items-center justify-between">
          <a href="https://parapaquetes.com/" class="text-xl font-bold text-gray-100 md:text-2xl hover:text-indigo-400">
            <img src="./assets/img/logo_mx.png" style="max-width: 140px" alt="logo de parapaquetes">
            <!-- <img :src="$t('header.primary.logo')" style="max-width: 140px" alt="logo de parapaquetes"> -->
          </a>
          <!--  Menu para movil -->
          <div @click="toggleNav" class="flex md:hidden">
            <button type="button" class="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400">
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
              </svg>
            </button>
          </div>
        </div>
        <!-- Menu para movil open: "block", Menu closed: "hidden" -->
        <ul :class="showMenu ? 'flex' : 'hidden'" class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:mt-0">
          <li>
            <p class="text-azul-light hover:text-indigo-400 text-sm font-semibold">{{$t("header.primary.country")}}</p>
            <LocaleSwitcher class="text-white h-10 bg-azul-default font-semibold"></LocaleSwitcher>
          </li>
        </ul>
      </nav>
    </div>
  <div>
    <nav class="px-6 py-2 md:py-6 md:bg-white" :class="showMenu ? 'border-2 shadow-md rounded bg-white' : 'border-0'">
      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul :class="showMenu ? 'flex flex-col' : 'hidden'" class="space-y-4 md:space-y-0 md:flex md:flex-wrap md:ml-10 md:mt-0">
        <li class="block md:mr-8">
          <a href="https://parapaquetes.com/" class="text-gray-500 hover:text-indigo-400 font-normal">{{$t('header.secondary.menu.home')}}</a>
        </li>
        <li class="block relative md:mr-8" v-click-away="closeDropdown">
            <!-- Dropdown toggle button -->
            <button @click="show = !show; show2 = false; show3 = false; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
              <span class="mr-4">{{$t('header.secondary.menu.products.title')}}</span>
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
            </button>

            <!-- Dropdown menu -->
            <div v-show="show" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
              <ul class="list-reset">
                <li class="relative">
                  <router-link to="/" v-bind:class="{ 'text-indigo-400': showSubmenu1 }" @click="showSubmenu1 = !showSubmenu1; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  <span class="flex-1">{{$t('header.secondary.menu.products.submenu.first_option.title')}}</span>
                  <span class="ml-2"> 
                    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                    </svg>
                  </span>      
                  </router-link>
                  <div v-show="showSubmenu1" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      <!-- <a v-for="(allProductSubmenu, i) of allProductSubmenu" :key="i" :href="allProductSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ allProductSubmenu.name }}
                      </a> -->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/bolsas-para-envios" data-type="menuitem">Bolsas para
                                        Env&iacute;os</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/sobres-con-burbujas" data-type="menuitem">Sobres con
                                        Burbuja</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/cajas-de-carton" data-type="menuitem">Cajas de Cart&oacute;n
                                        Normales</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/cajas-de-carton-auto-armables" data-type="menuitem">Cajas de
                                        Cart&oacute;n Autoarmables</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/cangureras-para-envio" data-type="menuitem">Sobres Packing
                                        List</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/material-de-embalaje" data-type="menuitem">Material de
                                        Embalaje</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/impresoras-y-rollos-termicos" data-type="menuitem">Impresoras
                                        y Rollos T&eacute;rmicos</a>
                    </div>
                  </div>
                </li>
                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu2 }" @click="showSubmenu2 = !showSubmenu2; showSubmenu1 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  <span class="flex-1">{{$t('header.secondary.menu.products.submenu.second_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu2" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      <!-- <a v-for="(shippingBagSubmenu, i) of shippingBagSubmenu" :key="i" :href="shippingBagSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ shippingBagSubmenu.name }}
                      </a>-->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/bolsas-para-envios" data-type="menuitem">Bolsas para
                                        Env&iacute;os Premium</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/bolsas-para-envios-premium-con-portaguias"
                                        data-type="menuitem">Bolsas para Env&iacute;os Premium con Portagu&iacute;as</a>
                                
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/bolsas-para-envios-100-recicladas" data-type="menuitem">Bolsas
                                        para Env&iacute;os 100% Recicladas</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-kraft-con-fuelle" data-type="menuitem">Sobres de Papel
                                        para Env&iacute;os</a>
                    </div>
                  </div>
                </li>
                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu3}" @click="showSubmenu3 = !showSubmenu3; showSubmenu1 = false; showSubmenu2 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6= false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">Cajas de Cartón Regulares (RSC) <!----></span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu3" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      <!--<a v-for="(plasticBagSubmenu, i) of plasticBagSubmenu" :key="i" :href="plasticBagSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ plasticBagSubmenu.name }}
                      </a>--><a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/cajas-de-carton-regulares" data-type="menuitem">Todas las
                        Medidas</a>

                    </div>
                  </div>
                </li>
                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu4}" @click="showSubmenu4 = !showSubmenu4; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu5 = false; showSubmen64 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">{{$t('header.secondary.menu.products.submenu.fourth_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu4" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      <!--<a v-for="(regularCardboardSubmenu, i) of regularCardboardSubmenu" :key="i" :href="regularCardboardSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ regularCardboardSubmenu.name }}
                      </a>-->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/cajas-de-carton-para-envios-con-pestana"
                                        data-type="menuitem">Cajas de Cart&oacute;n para Env&iacute;os</a>
                    </div>
                  </div>
                </li>
                <!-- <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu5}" @click="showSubmenu5 = !showSubmenu5; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">{{$t('header.secondary.menu.products.submenu.fifth_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu5" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      <a v-for="(assemblingCardboardSubmenu, i) of assemblingCardboardSubmenu" :key="i" :href="assemblingCardboardSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ assemblingCardboardSubmenu.name }}
                      </a>
                    </div>
                  </div>
                </li>-->
                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu6}" @click="showSubmenu6 = !showSubmenu6; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">{{$t('header.secondary.menu.products.submenu.sixth_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu6" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                     <!-- <a v-for="(bubbleEnvelopesSubmenu, i) of bubbleEnvelopesSubmenu" :key="i" :href="bubbleEnvelopesSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ bubbleEnvelopesSubmenu.name }}
                      </a>-->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-amarillos" data-type="menuitem">Sobres con
                                        Burbuja Amarillos</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-blancos" data-type="menuitem">Sobres con
                                        Burbuja Blancos</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-kraft" data-type="menuitem">Sobres con
                                        Burbuja Jiffylite Kraft</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbuja-rosas" data-type="menuitem">Sobres con Burbuja
                                        Rosas</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-aqua" data-type="menuitem">Sobres con Burbuja
                                        Aqua</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-plateados" data-type="menuitem">Sobres con
                                        Burbuja Plateados</a>
                    </div>
                  </div>
                </li>
                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu7}" @click="showSubmenu7 = !showSubmenu7; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">{{$t('header.secondary.menu.products.submenu.seventh_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu7" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      <!--<a v-for="(envelopesPackingListSubmenu, i) of envelopesPackingListSubmenu" :key="i" :href="envelopesPackingListSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ envelopesPackingListSubmenu.name }}
                      </a>-->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-packing-list-transparentes-1" data-type="menuitem">Sobres
                                        Packing List</a>
                    </div>
                  </div>
                </li>
                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu8}" @click="showSubmenu8 = !showSubmenu8; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu9 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">{{$t('header.secondary.menu.products.submenu.eighth_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu8" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      <!--<a v-for="(packingMaterialSubmenu, i) of packingMaterialSubmenu" :key="i" :href="packingMaterialSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ packingMaterialSubmenu.name }}
                      </a>-->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/cintas-adhesivas" data-type="menuitem">Cintas Adhesivas</a>
                                
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/rollos-de-plastico-para-emplayar"
                                        data-type="menuitem">Emplayes o Pelicula Stretch</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/esquineros-de-carton-kraft" data-type="menuitem">Esquineros de
                                        Cart&oacute;n Kraft</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/fleje-negro-1-2" data-type="menuitem">Fleje Negro 1/2"</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/grapa-para-fleje-1-2" data-type="menuitem">Grapa Para Fleje
                                        1/2"</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/materiales-de-proteccion-para-envios"
                                        data-type="menuitem">Material de Protecci&oacute;n</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/rafia-en-rollo-blanca" data-type="menuitem">Rafia en Rollo
                                        Blanca</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/rollo-de-papel-kraft" data-type="menuitem">Rollo de Papel
                                        Kraft</a>
                    </div>
                  </div>
                </li>



                <li class="relative">
                  <span class="flex-1"><a class="router-link-active router-link-exact-active flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/impresoras-y-rollos-termicos" >{{$t('header.secondary.menu.products.submenu.ninth_option.title')}}</a></span>
                  <!--<router-link v-bind:class="{ 'text-indigo-400': showSubmenu9}" @click="showSubmenu9 = !showSubmenu9; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu10 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1"><a href="https://parapaquetes.com/collections/impresoras-y-rollos-termicos" >{{$t('header.secondary.menu.products.submenu.ninth_option.title')}}</a></span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu9" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                      
                    </div>
                  </div>-->
                </li>



                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu10}" @click="showSubmenu10 = !showSubmenu10; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu11 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">{{$t('header.secondary.menu.products.submenu.tenth_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu10" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                     <!-- <a v-for="(paperMailingEnvelopeSubmenu, i) of paperMailingEnvelopeSubmenu" :key="i" :href="paperMailingEnvelopeSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ paperMailingEnvelopeSubmenu.name }}
                      </a>-->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-rigidos-kraft" data-type="menuitem">Sobres R&iacute;gidos
                                        para Env&iacute;os</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-kraft-con-fuelle" data-type="menuitem">Sobres Kraft con
                                        Fuelle para Env&iacute;os</a>
                    </div>
                  </div>
                </li>
                <li class="relative">
                  <router-link v-bind:class="{ 'text-indigo-400': showSubmenu11}" @click="showSubmenu11 = !showSubmenu11; showSubmenu1 = false; showSubmenu2 = false; showSubmenu3 = false; showSubmenu4 = false; showSubmenu5 = false; showSubmenu6 = false; showSubmenu7 = false; showSubmenu8 = false; showSubmenu9 = false; showSubmenu10 = false;" to="/" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    <span class="flex-1">{{$t('header.secondary.menu.products.submenu.eleventh_option.title')}}</span>
                    <span class="ml-2"> 
                      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" transform="rotate(270)">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
                      </svg>
                    </span>
                  </router-link>
                  <div v-show="showSubmenu11" class="bg-white md:shadow-md md:rounded border border-gray-300 text-sm relative md:absolute inset-l-full top-0 min-w-full w-56 md:w-96 z-30 mt-1">
                    <span class="md:absolute md:top-0 md:left-0 md:w-3 md:h-3 md:bg-white md:border md:transform md:rotate-45 md:-ml-1 md:mt-2"></span>
                    <div class="bg-white rounded w-full relative z-10 md:py-1">
                     <!-- <a v-for="(materialSubmenu, i) of materialSubmenu" :key="i" :href="materialSubmenu.link" class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                        {{ materialSubmenu.name }}
                      </a>-->
                      <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-rigidos-kraft" data-type="menuitem">Sobres R&iacute;gidos
                                        para Env&iacute;os</a>
                                <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-kraft-con-fuelle" data-type="menuitem">Sobres Kraft con
                                        Fuelle para Env&iacute;os</a>
                    </div>
                  </div>
                </li>   
                
                
                </ul>
            </div>
        </li>


        <li class="block relative md:mr-8" v-click-away="closeDropdown2">
          <button @click="show2 = !show2; show = false; show3 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Bolsas para Env&iacute;os</span>
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
          </button>
          <div v-show="show2" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
              <ul class="list-reset">
                <li class="relative">
                  <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/bolsas-para-envios" data-type="menuitem">Bolsas para Env&iacute;os
                                Premium</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/bolsas-para-envios-premium-con-portaguias" data-type="menuitem">Bolsas
                                para Env&iacute;os Premium con Portagu&iacute;as</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/bolsas-para-envios-100-recicladas" data-type="menuitem">Bolsas para
                                Env&iacute;os 100% Recicladas</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-kraft-con-fuelle" data-type="menuitem">Sobres de Papel para
                                Env&iacute;os</a>
                </li>
              </ul>
            </div>
        </li>
        <li class="block relative md:mr-8" v-click-away="closeDropdown4">
          <button @click="show4 = !show4; show = false; show3 = false; show2 = false; show5 = false;  show6 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Sobres con Burbuja</span>
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
          </button>
          <div v-show="show4" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
              <ul class="list-reset">
                <li class="relative">
                  <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-amarillos" data-type="menuitem">Sobres con Burbuja
                                Amarillos</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-blancos" data-type="menuitem">Sobres con Burbuja
                                Blancos</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-kraft" data-type="menuitem">Sobres con Burbuja
                                Jiffylite Kraft</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbuja-rosas" data-type="menuitem">Sobres con Burbuja
                                Rosas</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-aqua" data-type="menuitem">Sobres con Burbuja
                                Aqua</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/products/sobres-con-burbujas-plateados" data-type="menuitem">Sobres con Burbuja
                                Plateados</a>
                </li>
              </ul>
            </div>
        </li>
        <li class="block relative md:mr-8" v-click-away="closeDropdown5">
          <button @click="show5 = !show5; show = false; show3 = false; show3 = false; show2 = false; show4 = false;  show6 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Cajas de Cart&oacute;n </span>
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
          </button>
          <div v-show="show5" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
              <ul class="list-reset">
                <li class="relative">
                  <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/cajas-de-carton-regulares"
                                data-type="menuitem">Cajas de Cart&oacute;n Regulares</a>
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/cajas-de-carton-para-envios-con-pestanas"
                                data-type="menuitem">Cajas de Cart&oacute;n Para Env&iacute;o con Pesta&ntilde;a</a>
                        
                        <a class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/collections/cajas-de-carton-para-envios"
                                data-type="menuitem">Cajas de Cart&oacute;n para Env&iacute;os</a>
                </li>
              </ul>
            </div>
        </li>


       <!-- <li class="block relative md:mr-8" v-click-away="closeDropdown2">
          <button @click="show2 = !show2; show = false; show3 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">{{$t('header.secondary.menu.by_industry.title')}}</span>
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
          </button>
          <div v-show="show2" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
              <ul class="list-reset">
                <li class="relative" v-for="(byIndustryMenu, i) of byIndustryMenu" :key="i">
                  <a :href="byIndustryMenu.link" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ byIndustryMenu.name }} 
                  </a>
                </li>
              </ul>
            </div>
        </li>-->
        <li class="block md:mr-8">
          <a href="https://parapaquetes.com/pages/mayoreo" class="text-gray-500 hover:text-indigo-400 font-normal">Mayoreo</a>
        </li>
        <li class="block md:mr-8">
          <a href="https://parapaquetes.com/pages/facturacion" class="text-gray-500 hover:text-indigo-400 font-normal">{{$t('header.secondary.menu.billing')}}</a>
        </li>
        <li class="block md:mr-8">
          <a href="https://parapaquetes.com/pages/resenas" class="text-gray-500 hover:text-indigo-400 font-normal">{{$t('header.secondary.menu.reviews')}}</a>
        </li>
       <!-- <li class="block relative md:mr-8" v-click-away="closeDropdown3">
          <button @click="show3 = !show3; show = false; show2 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
           <span class="mr-4">{{$t('header.secondary.menu.more_information.title')}}</span>
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
          </button>
          <div v-show="show3" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
              <ul class="list-reset">
                <li class="relative" v-for="(moreInformationMenu, i) of moreInformationMenu" :key="i">
                  <a :href="moreInformationMenu.link" class="flex w-full items-start px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ moreInformationMenu.name }} 
                  </a>
                </li>
              </ul>
            </div>
        </li>-->
        
        
        <li class="block md:mr-8">
          <a href="https://empresa.parapaquetes.com/blog/" class="text-gray-500 hover:text-indigo-400 font-normal">{{$t('header.secondary.menu.blog')}}</a>
        </li>
        
        <li class="block md:mr-8">
          <a href="https://empresa.parapaquetes.com/" class="text-gray-500 hover:text-indigo-400 font-normal">Empresa</a>
        </li>

        <li class="block relative md:mr-8" v-click-away="closeDropdown3">
          <button @click="show3 = !show3; show = false; show2 = false; show4 = false; show5 = false; show6 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
           <span class="mr-4">Politicas</span>
              <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
              </svg>
          </button>
          <div v-show="show3" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
              <ul class="list-reset">
                <li class="relative" >
                  <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/pages/politicas-de-devoluciones-y-reembolsos" data-type="menuitem">Politica de
                                reembolsos y devoluciones</a>
                        <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/pages/politica-de-privacidad" data-type="menuitem">Pol&iacute;tica de
                                privacidad</a>
                        <a class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100" href="https://parapaquetes.com/pages/terminos-y-condiciones" data-type="menuitem">T&eacute;rminos y
                                condiciones</a>
                </li>
              </ul>
            </div>
        </li>

        <li class="block md:mr-8">
          <a href="https://empresa.parapaquetes.com/contact/" class="text-gray-500 hover:text-indigo-400 font-normal">{{$t('header.secondary.menu.contact')}}</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<router-view/>
</template>

<script>
import { mixin as VueClickAway } from "vue3-click-away";
import { ref } from 'vue';
import LocaleSwitcher from "./components/LocalesSwitcher.vue";

export default {
  mixins: [ VueClickAway ],
  components: { LocaleSwitcher },
  computed: {
    byIndustryMenu() {
      return [
        { link: "https://parapaquetes.com/collections/empaques-para-cosmeticos", name: this.$t('header.secondary.menu.by_industry.submenu.first_option')},
        { link: "https://parapaquetes.com/collections/empaques-para-libros", name: this.$t('header.secondary.menu.by_industry.submenu.second_option')},
        { link: "https://parapaquetes.com/collections/empaques-para-ropa", name: this.$t('header.secondary.menu.by_industry.submenu.third_option')},
        { link: "https://parapaquetes.com/collections/empaques-para-refacciones-y-autopartes", name: this.$t('header.secondary.menu.by_industry.submenu.fourth_option')},
        { link: "https://parapaquetes.com/collections/empaques-biodegradables-para-alimentos", name: this.$t('header.secondary.menu.by_industry.submenu.fifth_option')}
      ]
    },

    moreInformationMenu() {
      return [
        { link: "https://parapaquetes.com/pages/sobre-nosotros", name: this.$t('header.secondary.menu.more_information.submenu.first_option')},
        { link: "https://parapaquetes.com/pages/faqs", name: this.$t('header.secondary.menu.more_information.submenu.second_option')},
        { link: "https://envia.com/es-MX/rastreo", name: this.$t('header.secondary.menu.more_information.submenu.third_option')},
        { link: "https://parapaquetes.com/pages/aviso-de-privacidad", name: this.$t('header.secondary.menu.more_information.submenu.fourth_option')},
        { link: "https://parapaquetes.com/pages/politicas-de-devoluciones-y-reembolsos", name: this.$t('header.secondary.menu.more_information.submenu.fifth_option')},
        { link: "https://parapaquetes.com/pages/terminos-y-condiciones", name: this.$t('header.secondary.menu.more_information.submenu.sixth_option')}
      ]
    },

    allProductSubmenu() {
      return [
        {link: "https://parapaquetes.com/collections/bolsas-para-envios", name: this.$t('header.secondary.menu.products.submenu.first_option.submenu.first_option')},
        {link: "https://parapaquetes.com/collections/sobres-con-burbujas", name: this.$t('header.secondary.menu.products.submenu.first_option.submenu.second_option')},
        {link: "https://parapaquetes.com/collections/cajas-de-carton", name: this.$t('header.secondary.menu.products.submenu.first_option.submenu.third_option')},
        {link: "https://parapaquetes.com/collections/cajas-de-carton-auto-armables", name: this.$t('header.secondary.menu.products.submenu.first_option.submenu.fourth_option')},
        {link: "https://parapaquetes.com/collections/cangureras-para-envio", name: this.$t('header.secondary.menu.products.submenu.first_option.submenu.fifth_option')},
        {link: "https://parapaquetes.com/collections/material-de-embalaje", name: this.$t('header.secondary.menu.products.submenu.first_option.submenu.sixth_option')},
        {link: "https://parapaquetes.com/collections/impresoras-y-rollos-termicos", name: this.$t('header.secondary.menu.products.submenu.first_option.submenu.seventh_option')}
      ]
    },
    shippingBagSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/bolsas-para-envios-premium-al-por-mayor", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.first_option')},
        { link: "https://parapaquetes.com/products/bolsas-para-envios", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.second_option')},
        { link: "https://parapaquetes.com/products/bolsas-para-envios-premium-con-portaguias", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/bolsas-para-envios-100-recicladas", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/sobres-kraft-con-fuelle", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/bolsas-para-envios-biodegradables", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.fifth_option')},
        { link: "https://parapaquetes.com/collections/bolsas-para-envios-premium-con-disenos", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.sixth_option')},
        { link: "https://parapaquetes.com/products/bolsas-para-envios-premium-con-portaguias-al-por-mayor", name: this.$t('header.secondary.menu.products.submenu.second_option.submenu.seventh_option')}
      ]
    },

    plasticBagSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/bolsas-con-advertencia-de-asfixia", name: this.$t('header.secondary.menu.products.submenu.third_option.submenu.first_option')} ,
        { link: "https://parapaquetes.com/products/bolsas-de-celofan", name: this.$t('header.secondary.menu.products.submenu.third_option.submenu.second_option')} ,
        { link: "https://parapaquetes.com/products/bolsas-de-plastico-con-cierre-tipo-ziploc", name: this.$t('header.secondary.menu.products.submenu.third_option.submenu.third_option')} 
      ]
    },

    regularCardboardSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/cajas-de-carton-regulares", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.first_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-cubicas", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.second_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-envios-de-1-kg", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-envios-de-2-kg", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-envios-de-3-kg", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.fifth_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-envios-de-4y5-kg", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.sixth_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-envios-de-6a10-kg", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.seventh_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-a-mayoreo", name: this.$t('header.secondary.menu.products.submenu.fourth_option.submenu.eighth_option')}
      ]
    },

    assemblingCardboardSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-envios", name: this.$t('header.secondary.menu.products.submenu.fifth_option.submenu.first_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-cuadradas", name: this.$t('header.secondary.menu.products.submenu.fifth_option.submenu.second_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-tazas", name: this.$t('header.secondary.menu.products.submenu.fifth_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-empaques", name: this.$t('header.secondary.menu.products.submenu.fifth_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/cajas-de-carton-para-ropa", name: this.$t('header.secondary.menu.products.submenu.fifth_option.submenu.fifth_option')}
      ]
    },

    bubbleEnvelopesSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/sobres-con-burbujas-amarillos", name: this.$t('header.secondary.menu.products.submenu.sixth_option.submenu.first_option')},
        { link: "https://parapaquetes.com/products/sobres-con-burbujas-kraft", name: this.$t('header.secondary.menu.products.submenu.sixth_option.submenu.second_option')},
        { link: "https://parapaquetes.com/products/sobres-con-burbujas-blancos", name: this.$t('header.secondary.menu.products.submenu.sixth_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/sobres-con-burbujas-rosas", name: this.$t('header.secondary.menu.products.submenu.sixth_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/sobres-con-burbujas-aqua", name: this.$t('header.secondary.menu.products.submenu.sixth_option.submenu.fifth_option')},
        { link: "https://parapaquetes.com/products/sobres-con-burbujas-plateados", name: this.$t('header.secondary.menu.products.submenu.sixth_option.submenu.sixth_option')}
      ]
    },

    envelopesPackingListSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/sobres-canguro-para-guias-de-envio", name: this.$t('header.secondary.menu.products.submenu.seventh_option.submenu.first_option')},
        { link: "https://parapaquetes.com/products/sobres-packing-list-transparentes", name: this.$t('header.secondary.menu.products.submenu.seventh_option.submenu.second_option')},
        { link: "https://parapaquetes.com/products/sobres-packing-list-enclosed", name: this.$t('header.secondary.menu.products.submenu.seventh_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/sobres-packing-list-invoice-enclosed", name: this.$t('header.secondary.menu.products.submenu.seventh_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/sobres-full-packing-list-enclosed", name: this.$t('header.secondary.menu.products.submenu.seventh_option.submenu.fifth_option')}
      ]
    },

    packingMaterialSubmenu() {
      return [
        { link: "https://parapaquetes.com/collections/cintas-adhesivas", name: this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.first_option')},
        { link: "https://parapaquetes.com/collections/rollos-de-plastico-para-emplayar", name:  this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.second_option')},
        { link: "https://parapaquetes.com/collections/materiales-de-proteccion-para-envios", name:  this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/rafia-en-rollo-blanca", name: this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/rollo-de-papel-kraft", name: this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.fifth_option')},
        { link: "https://parapaquetes.com/products/fleje-negro-1-2", name: this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.sixth_option')},
        { link: "https://parapaquetes.com/products/grapa-para-fleje-1-2", name: this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.sixth_option')},
        { link: "https://parapaquetes.com/products/esquineros-de-carton-kraft", name: this.$t('header.secondary.menu.products.submenu.eighth_option.submenu.seventh_option')}
      ]
    },

    printerThermalRollSubmenu() {
      return [
        { link: "https://parapaquetes.com/collections/impresoras-termicas", name: this.$t('header.secondary.menu.products.submenu.ninth_option.submenu.first_option')},
        { link: "https://parapaquetes.com/collections/rollos-termicos", name: this.$t('header.secondary.menu.products.submenu.ninth_option.submenu.second_option')}
      ]
    },

    paperMailingEnvelopeSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/sobres-rigidos-kraft", name: this.$t('header.secondary.menu.products.submenu.tenth_option.submenu.first_option')},
        { link: "https://parapaquetes.com/products/sobres-kraft-con-fuelle", name: this.$t('header.secondary.menu.products.submenu.tenth_option.submenu.second_option')},
        { link: "https://parapaquetes.com/products/sobres-biodegradables-para-envios-honeycomb", name: this.$t('header.secondary.menu.products.submenu.tenth_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/sobres-kraft-con-fuelle-para-envios-al-por-mayor-1-tarima", name: this.$t('header.secondary.menu.products.submenu.tenth_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/sobres-rigidos-kraft-al-por-mayor-1-tarima", name: this.$t('header.secondary.menu.products.submenu.tenth_option.submenu.fifth_option')}
      ]
    },

    materialSubmenu() {
      return [
        { link: "https://parapaquetes.com/products/muestras-de-cajas-de-carton-regulares", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.first_option')},
        { link: "https://parapaquetes.com/products/muestras-de-bolsas-para-envios-premium", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.second_option')},
        { link: "https://parapaquetes.com/products/muestras-de-bolsas-para-envios-premium-con-portaguias", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.third_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-con-burbuja-amarillos-para-envios", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.fourth_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-con-burbuja-blancos-para-envios", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.fifth_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-con-burbuja-plateados-metalicos-para-envios", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.sixth_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-de-polietileno-con-burbuja-rosas-para-envios", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.seventh_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-de-polietileno-con-burbuja-aqua-para-envios", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.eighth_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-con-burbuja-kraft", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.ninth_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-packing-list", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.tenth_option')},
        { link: "https://parapaquetes.com/products/muestras-de-bolsas-biodegradables-para-envios", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.eleventh_option')},
        { link: "https://parapaquetes.com/products/muestras-de-sobres-kraft-con-fuelle-para-envios", name: this.$t('header.secondary.menu.products.submenu.eleventh_option.submenu.twelfth_option')}
      ]
    }
  },

  data() {
    return {
      showDropdown: false,
      showSubmenu1: false,
      showSubmenu2: false,
      showSubmenu3: false,
      showSubmenu4: false,
      showSubmenu5: false,
      showSubmenu6: false,
      showSubmenu7: false,
      showSubmenu8: false,
      showSubmenu9: false,
      showSubmenu10: false,
      showSubmenu11: false

    }
  },
  setup() {
    let showMenu = ref(false);
    let show = ref(false);
    let show2 = ref(false);
    let show3 = ref(false);
    let show4 = ref(false);
    let show5 = ref(false);
    const toggleNav = () => (showMenu.value = !showMenu.value);
    return { showMenu, show, show2, show3, show4, show5, toggleNav };
  },

  methods: {
    closeDropdown() {
      this.show = false;
    },
    closeDropdown2() {
      this.show2 = false;
    },
    closeDropdown4() {
      this.show4 = false;
    },
    closeDropdown5() {
      this.show5 = false;
    },
    closeDropdown6() {
      this.show6 = false;
    },
    closeDropdown3() {
      this.show3 = false;
    }
  }
}

</script>

<style>
  /* For 1024 Resolution */  
  @media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)
{
.md\:mr-8 {margin-right:0.9rem !important;}
.mr-4 {margin-right:0rem !important}
}  

</style>
<style scoped>
.inset-l-full {
}

@media (min-width: 768px) { 
  .inset-l-full {
    left: 100%;
}
}
</style>