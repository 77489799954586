export default {
  "header": {
    "primary": {
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country/region"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["@", "/assets/img/logo_eu.png"])}
    },
    "secondary": {
      "menu": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
        "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
        "quote_wholesale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote Wholesale"])},
        "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
        "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
        "products": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
          "submenu": {
            "first_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Products"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poly Mailers"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bubble Mailers"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard Boxes (RSC)"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Assembling Cardboard Boxes"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packing List Envelopes"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging Material"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printers and Thermal Rolls"])}
              }
            },
            "second_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poly Mailers"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Poly Mailers"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Poly Mailer"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium Poly Mailers with Pouch"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Recycled Poly Mailers"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper Mailing Envelopes"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biodegradable Poly Mailers"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designed Poly Mailers"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Mailing Bags with Pouch"])}
              }
            },
            "third_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic Bags"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suffocation Warning Bags"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellophane Bags"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic Ziploc Bags"])}
              }
            },
            "fourth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard Boxes (RSC)"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Box Sizes"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cubic Boxes"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes for 1 kg Shipments"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes for 2 kg Shipments"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes for 3 kg Shipments"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes for 4 kg and 5 kg Shipments"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boxes for 6 to 10 kg Shipments"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Shipping Boxes"])}
              }
            },
            "fifth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self Assembling Shipping Boxes"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard Shipping Boxes"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Square Cardboard Shipping Boxes"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard Shipping Boxes for Mugs"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard Shipping Boxes for Packaging"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard Shipping Boxes for Clothes"])}
              }
            },
            "sixth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bubble Mailers"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow Bubble Mailers"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiffylite Kraft Bubble Mailers"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Bubble Mailers"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pink Bubble Mailers"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blue Bubble Mailers"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metallic Bubble Mailers"])}
              }
            },
            "seventh_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packing List Envelopes"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packing List Envelopes"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Transparent Packing List"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Orange Packing List Enclosed"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale “Invoice Enclosed” Packing List Envelopes"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale Full “Invoice Enclosed” Packing List Envelopes"])}
              }
            },
            "eighth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packaging Material"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adhesive Tapes"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stretch Film Rolls"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection Material"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Rolled Raffia"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraft Paper Roll"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black Strap 1/2”"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strapping Staple 1/2”"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraft Cardboard Corners"])}
              }
            },
            "ninth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printers and Thermal Rolls"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thermal Printers"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thermal Rolls and Labels"])}
              }
            },
            "tenth_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paper Mailers"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rigid Envelopes for Shipping"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraft Gusseted Mailing Envelopes"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bubble Paper Envelopes “Honeycomb”"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraft Gusseted Envelopes for Wholesale Shipping (1 Pallet)"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraft Rigid Envelopes Wholesale (1 Pallet)"])}
              }
            },
            "eleventh_option": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples"])},
              "submenu": {
                "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard Box Samples (RSC)"])},
                "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poly Mailer Samples"])},
                "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poly Mailer with Pouch Samples"])},
                "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yellow Bubble Mailer Samples"])},
                "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Bubble Mailer Samples"])},
                "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metallic Silver Bubble Mailer Samples"])},
                "seventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pink Poly Bubble Mailer Samples"])},
                "eighth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqua Bubble Poly Mailer Samples"])},
                "ninth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jiffylite Kraft Bubble Mailer Samples"])},
                "tenth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packing List Envelope Samples"])},
                "eleventh_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biodegradable Poly Mailer Samples"])},
                "twelfth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraft Gusseted Envelope Samples"])}
              }
            }
          }
        },
        "by_industry": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop by Industry"])},
          "submenu": {
            "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosmetics Shipping Packaging"])},
            "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book Shipping Packaging"])},
            "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothes Shipping Packaging"])},
            "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spare and Auto Parts Shipping Packaging"])},
            "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biodegradable Food Packaging"])}
          }
        },
        "more_information": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Info"])},
          "submenu": {
            "first_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
            "second_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
            "third_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking"])},
            "fourth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
            "fifth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returns and Refunds Policy"])},
            "sixth_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])}
          }
        }
      }
    }
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of cardboard box"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose one of the following options:"])},
    "products": {
      "boxes_regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Boxes"])},
      "boxes_self_assembling_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-assembling Boxes"])}
    }
  },
  "calculator": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacture of Customized Regular Cardboard Boxes"])},
    "num_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["134 Reviews"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabricate a packing and shipping box to the exact measurements and specifications for your product."])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use our quote. Enter the internal measurements you want for your box, length, width and height, to calculate the total, unit cost and the minimum manufacturing cost for any measurement of corrugated cardboard boxes."])},
    "link_informative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href='https://empresa.parapaquetes.com/2023/08/28/que-son-las-flautas-y-la-resistencia-ect-en-el-carton-corrugado/' target='_blank' class='text-blue-600 text-sm font-semibold hover:underline'>All about Flutes and ECT in corrugated cardboard <i class='fa-solid fa-link text-sm'></i></a>"])},
    "breadcrumbs": {
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "path": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Boxes"])}
      }
    },
    "label": {
      "type_flute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of flute:"])},
      "type_resistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box resistance:"])},
      "type_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select unit:"])}
    },
    "input": {
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
      "tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tall"])}
    },
    "value": {
      "flute_c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flute C Kraft"])},
      "flute_bc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flute BC Kraft"])},
      "flute_e": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flute E Kraft"])}
    },
    "button": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])}
    },
    "result": {
      "manufacturing_minimun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MANUFACTURING MINIMUM"])},
      "price_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRICE UNIT"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
      "text_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of box, length, width and height to make the quote"])}
    },
    "manufacturing_policies": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturing policies:"])},
      "list": {
        "no_returns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once the payment is made, no cancellations or returns are accepted."])},
        "error_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manufacture of the boxes has a +- 10% margin of error in the number of pieces"])},
        "envio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping cost will be provided by our sales team and added to the quote"])}
      }
    },
    "error": {
      "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert!"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important:"])},
      "required": {
        "type_flute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The type of flute is required"])},
        "color_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The color of the box is required"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length cannot be empty"])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width cannot be empty"])},
        "tall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tall cannot be empty"])},
        "terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to terms and conditions"])}
      },
      "number": {
        "invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is an invalid number"])}
      },
      "minimum_constraint": {
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manufacture of your box requires a die (?) because the chosen size is smaller than the standard. The cost of the die is additional to the price of the boxes."])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The manufacture of your box requires a die (?) because the chosen size is smaller than the standard. The cost of the die is additional to the price of the boxes."])}
      },
      "max_constraint": {
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The length of the sheet must be less than 290 cm. To calculate the length of the sheet, the following formula is used: ((Length*2)+(Width*2)+6)"])},
        "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The width of the sheet must be less than 140 cm. To calculate the width of the sheet, the following formula is used: (Width + Height + 3)"])}
      }
    },
    "checkbox": {
      "terms_conditions": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree with the <a href='/terms-conditions' target='_blank' class='text-blue-600 hover:underline dark:text-blue-500'>terms and conditions</a> and manufacturing policies"])}
      }
    }
  },
  "confirmation": {
    "title": {
      "send_quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send quote"])},
      "quote_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order summary"])}
    },
    "text": {
      "dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions"])},
      "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
      "amount_boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of boxes"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])},
      "cardboard_boxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardboard boxes"])},
      "for_manufacturing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for Manufacturing"])}
    },
    "span": {
      "without_iva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["without iva"])},
      "with_iva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with iva"])}
    },
    "button": {
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business (Optional)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street and outside number"])},
      "suburb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suburb"])},
      "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])}
    },
    "error": {
      "required": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name field is required"])},
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last name field is required"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email field is required"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone field is required"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The street field is required"])},
        "suburb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The suburb field is required"])},
        "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The postal code field is required"])}
      },
      "validation": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field has to be a valid email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field has to be a valid phone"])}
      }
    },
    "alert": {
      "successful": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been sent successfully!"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the following 24 business hours, a sales executive will contact you to follow up on your request."])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href='https://empresa.parapaquetes.com/contact/' target='_blank' style='font-weight: 700; text-decoration: underline; text-decoration-thickness: 1px; text-align: center;'> For any questions or comments, click here to contact us</a>"])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending the quote"])}
      }
    }
  },
  "page_self_assembling_box": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacture of Customized Self-Assembling Cardboard Boxes"])},
    "num_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23 reviews"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our online quote for Custom Self-Assembling Cardboard Boxes is still under construction. Share your information with us using the following form and we will contact you to help you quote the boxes you want."])},
    "breadcrumbs": {
      "root": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "path": {
        "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-assembling Boxes"])}
      }
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business (Optional)"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write what you need (Optional)"])}
    },
    "contact_means": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information you can contact us by"])},
      "social_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whatsapp"])}
    },
    "error": {
      "required": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The name field is required"])},
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last name field is required"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email field is required"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone field is required"])}
      },
      "validation": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field has to be a valid email"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field has to be a valid phone"])}
      }
    },
    "button": {
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
    },
    "alert": {
      "successful": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Tu solicitud ha sido enviada exitosamente!"])},
        "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En las siguientes 24 horas hábiles un ejecutivo de ventas se comunicará para darle seguimiento a tu solicitud."])},
        "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<a href='https://empresa.parapaquetes.com/contact/' target='_blank' style='font-weight: 700; text-decoration: underline; text-decoration-thickness: 1px; text-align: center;'> Para cualquier duda o comentario, haz click aqui para contactarnos</a>"])}
      },
      "error": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al enviar la cotización"])}
      }
    }
  },
  "terms_conditions": {
    "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legend:<span class='font-semibold italic'> “By placing my order, I confirm that I have read and agree to the <span class='underline'>Terms and Conditions</span> of purchase”.</span>"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions of purchase"])},
    "main_content": {
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='font-bold'>Welcome to For Packages!</span> Supplies For Packages, S.A. de C.V. and/or its subsidiaries (“For Packages”) has its domicile located at Belisario Domínguez, No. 2470, 3rd floor, Colonia Obispado, in Monterrey, Nuevo León, C.P. 64060, and we offer you website features and other products, as well as packaging services. By entering and using this Internet portal, whose domain name is <a href='https://parapaquetes.com/' class='text-blue-600'>www.parapaquetes.com</a> (“Site Web”), property of For Packages, you as a user are accepting the Terms and Conditions of Purchase (“Terms”) contained in this agreement and expressly declare your acceptance, using for this purpose electronic means, in terms of the provisions of the Article 1803 of the Federal Civil Code."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The use of this Internet page grants the general public the status of user (hereinafter “User”/ “Users”) and implies full and unconditional acceptance of each and every one of the general and particular conditions included in these Terms at the moment the User accesses the Website. If you do not fully and completely accept these Terms, you must refrain from accessing, using, observing and purchasing on our website. In the event that you access, use, observe and/or purchase through our Website, it will be considered as an absolute and express acceptance on your part of these Terms."])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any modification to these Terms will be made when For Packages considers it appropriate, being the sole responsibility of the User to ensure that they are aware of such modifications."])},
      "fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please review the following guidelines carefully. If you have any questions regarding the terms and conditions, please write an email to <a href='mailto:contact", "@", "parapaquetes.com' class='text-blue-600'>contact", "@", "parapaquetes.com</a> or send us a WhatsApp message to the number <a href='tel:8126070095' class='text-blue-600'>+52 8126070095</a>."])},
      "fifth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a wide range of products and sometimes additional conditions may apply. By entering and purchasing our packaging materials, you are subject to the following purchase conditions:"])}
    },
    "service_paragraph": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Packages, it offers you the service of manufacturing cardboard boxes to the exact measurements and characteristics that you indicate when filling out your form and making your purchase on our Website."])}
    },
    "prices_taxes": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices, taxes, handling charges and specifications"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You agree that everything related to prices, materials and services on the For Packages Website are subject to change without notice. Material descriptions are as accurate as possible; however, they could vary."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Packages reserves the right to make price and product adjustments for reasons including, but not limited to, changes in market conditions, discontinued products, unavailability, manufacturer price changes, and advertising errors."])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The published prices are net amounts in Mexican pesos and do not include shipping costs, handling charges, taxes and/or duties or special packaging requirements for exports. The taxes or tariffs will be paid by the User, being his total responsibility."])}
    },
    "service_payment_billing": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service payment and billing"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must make the total payment of the value of the services that you wish to acquire in a single exhibition and at the time of processing your purchase order, and it must be fully settled so that we can send your order. Once your payment is received, processed and duly reflected, you will receive a confirmation of purchase to your registration email. For Packages accept credit cards, deposits, bank transfers, payments by Apple Pay, Dinners Club, Discover, Meta Pay, Google Pay, Mastercard, PayPal, Shop Pay, Venmo, Visa, American Express."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you require an invoice, you can process it in the “ Billing” section on our Website, entering your order number and the total amount without cents. If your information has an error and you generate an incorrect invoice, you should contact For Packages at our email address at <a href='mailto:contacto", "@", "parapaquetes.com' class='text-blue-600'>contact", "@", "parapaquetes.com</a> or chat so that it can be canceled and we can generate the new invoice for you."])}
    },
    "shipping_delivery_products": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping and delivery of products"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipments can only be made within the territory of Mexico."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The average manufacturing time for your boxes to the exact size requested is approximately 15 (fifteen) business days, once you have received your purchase confirmation email. When your order is ready, For Packages will inform you through an email and will share a list of available packages according to your address to send you your order. Once we share that list with you, you will have a maximum period of 24 hours to choose the parcel of your choice and give us an answer. Otherwise, if for any reason we do not receive your response within the mentioned period of time, by accepting these Terms you authorize For Packages to select a random parcel to send your order, without liability for the latter."])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We handle options to purchase urgent shipments or with a specific package (in addition to those that appear on the list that we send you); These options can be purchased at an additional cost. To do this, contact the For Packages staff through Customer Service."])},
      "fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the package is selected, you will receive a notification from For Packages informing you that your order has been shipped, as well as your tracking guide number. The delivery of your order will depend on the times established by the parcel of your selection, we invite you to review the terms and conditions established in the logistics platforms to have an estimated delivery date."])},
      "fifth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case you request a specific package, we invite you to review the terms and conditions established in your logistics platform to have an estimated delivery date. For Packages, it does not control or manipulate the distribution of your order, since the logistics contract is with a third party. Therefore, once your order is sent, For Packages will not have any responsibility for delay in delivery (once your package has been sent), for damage or handling of your order. Notwithstanding the foregoing, we can support you in support issues and follow-up of your case with the corresponding parcel service."])},
      "sixth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the event that there is a problem or incident with your package, it has been reviewed by a competent authority, a situation of force majeure, or theft, contact customer service immediately so that they can provide you with the necessary support."])}
    },
    "responsibility": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsibility"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Packages, it is responsible for sending you the amount of packaging material that you have requested in your purchase order according to the characteristics that you requested. If your order arrives incomplete, you can contact Customer Service."])},
      "second_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You accept that the choice of packaging material and its purchase is your total responsibility, therefore, under this act, you fully demarcate For Packages."])},
      "third_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You acknowledge that For Packages is not responsible for the material that you are going to transport inside the products that you purchase on our Website. It is the responsibility of the User to review the type of material that will be transported and choose the packaging that best suits him so that his material is preserved. If the product that you transport inside the packaging material purchased on our Website suffers damage during delivery, it will be the sole responsibility of the User, for which reason the latter demarcates Para Parquetes under this act from any type of responsibility that it may have for damage to the material. transported by the User in the Parcel products."])},
      "fourth_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case you do not know what type of packaging is the most suitable for your material and/or needs, you can contact For Packages at the email: <a href='mailto:contacto", "@", "parapaquetes.com' class= 'text-blue-600'>contact", "@", "parapaquetes.com</a>, or send us a WhatsApp message at <a href='tel:8126070095' class='text-blue-600'>+52 8126070095</a> to provide you with personalized advice, which will be without any responsibility at your expense."])}
    },
    "service_guarantee": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Guarantee"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Packages and its affiliates expressly disclaim all express or implied warranties relating to the products, including, without limitation, any warranties of merchantability or particular purpose."])}
    },
    "copyright": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership of Materials, Trademarks and Copyrights"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The content of this Website may not be copied, disseminated, downloaded, transmitted, duplicated or reproduced without the prior written permission of For Packages or the corresponding intellectual property owner. This Website and all its content, including all images, designs, photographs, text and other materials (the  “Content”) are copyright, intellectual property or trademarks owned by and/or licensed by For Packages or its affiliates , or are the property of their respective owners."])}
    },
    "privacy": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult the Privacy Policy of For Packages for more detailed information on the collection of data and the conditions of said process by us, as well as the confidentiality and security measures that we implement. By visiting our Website, processing or making any purchase, you consent to the processing of your personal information in accordance with our Privacy Policy."])}
    },
    "applicable": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicable"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By accessing this Website you agree that the laws of the City of Monterrey, United Mexican States (without regard to conflicts of law principles) will apply to all matters related to the use of this Website and the purchase of products available through it. Any conflict, dispute or litigation that arises from these Terms, or any purchase you make through this Website, will be carried out exclusively in the City of Monterrey and you as a User and client give your consent for the jurisdiction of the competent authorities and Courts is the city mentioned, waiving the right to change venue."])}
    },
    "entire_agreement": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entire Agreement"])},
      "first_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms constitute the entire covenant and agreement between For Packages and you (User) and supersede any agreement or agreement previously entered into, including any oral statement made by representatives of For Packages. To the extent that anything relating to the Website conflicts or is inconsistent with these Terms, these Terms will take precedence."])}
    },
    "text_terms_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONCE I HAVE READ THESE TERMS AND CONDITIONS, I ACCEPT THE VERACITY AND LEGITIMATION OF THEM AND I ACCEPT THE GUIDELINES BY WHICH THE BUSINESS RELATIONSHIP WILL BE GOVERNED."])}
  }
}