<template>
  <div class="relative inline-block">
       <select v-model="$i18n.locale" class="dropdown-content bg-azul-default focus:outline-none cursor-pointer">
         <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale" class="text-gray-500 h-10 bg-white p-8">
           {{ locale =='es' ? "México (MXN $)": "United States (USD $)" }}
         </option>
       </select>
     </div>
 </template>
   
 <script>
     export default {
         name: "LocaleSwitcher",
         data() {
           return { locales: ["es", "en"] };
         }
     };
 </script>