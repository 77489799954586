import { createRouter, createWebHistory } from 'vue-router'
import NoPageFound from '@/modules/shared/pages/NoPageFound'
 
const routes = [
  {
    path: '/',
    name: 'dashboardPage',
    component: () => import(/* webpackChunkName: "DashboardPage" */ '@/modules/quoter/pages/DashboardPage')
  },
  {
    path: '/regular-boxes',
    name: 'calculatorPage',
    component: () => import(/* webpackChunkName: "CalculatorPage" */ '@/modules/quoter/pages/RegularBoxes/CalculatorPage')
  },
  {
    path: '/self-assembling-boxes',
    name: 'formPage',
    component: () => import(/* webpackChunkName: "CalculatorPage" */ '@/modules/quoter/pages/SelfAssemblingBoxes/FormPage')
  },
  {
    path: '/confirmation',
    name: 'confirmPage',
    component: () => import(/* webpackChunkName: "ConfirmationPage" */ '@/modules/quoter/pages/RegularBoxes/ConfirmPage'),
  },
  {
    path: '/terms-conditions',
    name: 'termsConditionsPage',
    component: () => import(/* webpackChunkName: "TermsConditionsPage" */ '@/modules/shared/pages/TermsConditionsPage'),
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'noPageFound',
    component: NoPageFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
